import React, { useState } from 'react';

import Container from '../visual/Container';
import Textbox from '../visual/Textbox';
import Select from '../visual/Select';
import Color from '../visual/Color';
import Button from '../visual/Button';
import Card from '../visual/Card';


const AddStaff = ({ onSave, error }) => {

    const [name, setName] = useState('');
    const [font, setFont] = useState('Arial')

    const [fontColor, setFontColor] = useState('#000000')
    const [bgColor, setBgColor] = useState("#ffffff")

    const update = (event) => {
        if (event.target.name === 'name') {
            setName(event.target.value);
        }

        if (event.target.name === 'font') {
            setFont(event.target.value);
        }
    }

    const changeFontColor = (color) => {
        setFontColor(color);
    }

    const changeBgColor = (color) => {
        setBgColor(color);
    }

    const save = () => {
        let data = {
            name: name,
            font: font,
            color: fontColor,
            bg: bgColor
        }

        onSave(data);
    }

    const webSafeFonts = [
        'Arial',
        'Arial Black',
        'Verdana',
        'Tahoma',
        'Trebuchet MS',
        'Impact',
        'Times New Roman',
        'Didot'
    ]

    return (
        <Container
            style={container}
        >
            <Textbox
                type="text"
                label="Name"
                name="name"
                onChange={update}
                value={name}
                txtstyle={{ padding: '5px' }}
                autoComplete="off"
            />
            <Select
                id="font"
                label="Font"
                name="font"
                list={webSafeFonts}
                containerStyle={{ padding: '5px' }}
                value={font}
                onChange={update}
            />

            <Color
                label="Font Color"
                color={fontColor}
                onChangeColor={changeFontColor}
                containerStyle={{ padding: '5px' }}
            />

            <Color
                label="Background Color"
                color={bgColor}
                onChangeColor={changeBgColor}
                containerStyle={{ padding: '5px' }}
            />

            <div>
                <label style={lblStyle}>Preview</label>
                <div style={{ padding: '5px' }}>
                    <Card
                        style={{
                            backgroundColor: bgColor,
                            minHeight: '25px'
                        }}
                    >
                        <p
                            style={{
                                padding: '5px',
                                fontFamily: font,
                                color: fontColor,
                            }}
                        >{name}</p>
                    </Card>
                </div>
            </div>

            <Button
                onClick={save}
                width="auto"
                btnstyle={
                    {
                        margin: '5px'
                    }
                }
            >
                Save
            </Button>

            {error != '' ? <div style={{
                padding: '5px',
                fontSize: '14px',
                color: 'var(--error-color)'
            }}>
                <p>{error}</p>
            </div> : ''}

        </Container>
    );
}

const container = {
    flexDirection: 'column',
    marginTop: '10px',
    backgroundColor: 'var(--card-surface)'
}

const lblStyle = {
    fontSize: '15px',
    fontWeight: '450',
    marginBottom: '3px',
}

export default AddStaff;