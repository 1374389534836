import axios from "axios";
import moment from "moment";

export function saveProjectUtil(data, passCallback, failCallback) {
    let token = localStorage.getItem('whiteboard_token');
    let body = {
        title: data.name,
        status: 'publish',
        acf: {
            position: data.position,
            projects: data.projects,
        }
    }

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let url = "/wp-json/wp/v2/project";

    axios.post(url, body, header).then(response => {
        passCallback(response);
    }).catch(error => {
        failCallback(error);
    })
}

export async function updateProjectUtil(data, passCallback, failCallback) {
    let token = localStorage.getItem('whiteboard_token');
    let body = {
        status: 'publish',
        acf: {
            projects: data.project_ID,
        }
    }

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let url = "/wp-json/wp/v2/project/" + data.id;
    let res;
    try {
        res = await axios.post(url, body, header)
    } catch (err) {
        return err;
    }

    return res;

}

export function getWeeklyDay(day) {

    let date = moment().startOf('week').add(day, 'days');
    return date.format("DD/MM/YYYY");

}

export function dateToString(date, daysToAdd) {
    let d = date.clone();
    let result = d.add(daysToAdd, 'days');
    return result.format("DD/MM/YYYY");

}

export function replace(oldArray, oldData, id, newItem, option = null) {
    // let newArray = oldArray.filter(a => a.id != id);
    let newArray = JSON.parse(JSON.stringify(oldArray));

    if (!option) {
        if (oldData.project_ID != "") {
            newItem.project_ID = oldData.project_ID + newItem.project_ID;
            // console.log("mistatch", newItem);
        }
    }

    for (let item of newArray) {
        if (item.id == id) {
            let n = { ...item, ...newItem }
            Object.assign(item, n)
            break;
        }


    }

    return newArray;
}