import React from 'react';

const Container = (props) => {

    const style = {
        backgroundColor: 'var(--main-bg-color)',
        display: 'flex',
        flex: 1,
        height: '100%',
        fontFamily: 'var(--main-font)'
    }

    return (
        <div {...props} style={{ ...style, ...props.style }}>
            {props.children}
        </div>
    );
}

export default Container;