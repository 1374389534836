import React, { useState } from 'react';

import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';


const CustomIcon = (props) => {
    return (
        <div style={{
            position: 'absolute',
            top: 10,
            left: 10,
        }}>
            <AiOutlineMinus
                style={{
                    fontSize: '25px',
                    cursor: 'pointer',
                }}
                onClick={props.onClick}
            />
        </div >
    )
}

const MinimiseRow = (props) => {

    const [minimise, setMinimise] = useState(false);

    const toggle = () => {
        setMinimise(!minimise);
    }

    const container = {
        gridColumn: "1 / -1",
        border: 'var(--main-border)',
        padding: '3px',
    }



    let [first, array] = props.children;
    let newProps;
    if (props.children) {
        let icon = <CustomIcon onClick={toggle} />
        let child = { ...props.children[0] }
        let childProp = { ...child.props }
        let newChildren = [{ ...childProp.children }, icon]

        //change the values
        childProp = { ...childProp, children: newChildren }
        child = { ...child, props: childProp }
        newProps = [child, array]

    }

    return (
        <>
            {!minimise ?
                <>
                    {newProps}
                </>
                :
                <div style={container} onClick={toggle}>
                    <AiOutlinePlus
                        style={{
                            fontSize: '25px',
                            cursor: 'pointer',
                        }}
                    />
                </div>
            }
        </>
    );
}

export default MinimiseRow;