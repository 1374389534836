import React, { useEffect, useRef, useState } from 'react';

import { Projects, refreshProject, OpenToolbar, Auth, CurrentWeek, ProjectChanges, MultiSelect } from '../State';
import { AiFillCaretLeft, AiFillCaretRight, AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';
import { CgPlayForwards } from 'react-icons/cg';
import { BiSelectMultiple } from 'react-icons/bi';
import { dateToString } from '../util/Functions';
import Day from '../components/Day';

import { useRecoilState } from 'recoil';
import axios from 'axios';
import FloatingButton from '../components/FloatingButton';
import MinimiseRow from '../components/MinimiseRow';



const ProjectPanel = () => {

    const [refresh, setRefresh] = useRecoilState(refreshProject);
    const [projects, setProjects] = useRecoilState(Projects);
    const [changes, setChanges] = useRecoilState(ProjectChanges)
    const [open, setOpen] = useRecoilState(OpenToolbar);
    const [auth, setAuth] = useRecoilState(Auth);
    const [week, setWeek] = useRecoilState(CurrentWeek);
    const [multiSelect, setMultiSelect] = useRecoilState(MultiSelect);

    //refs
    const projectsRef = useRef([]);

    //scroll
    const [scroll, setScroll] = useState(false);
    const carouselRef = useRef([]);
    const mainContainerRef = useRef();
    var carouselIndex = 0;
    const timerRef = useRef(null);

    const container = {
        backgroundColor: 'var(--alt-bg-color)',
        width: 'auto',
        height: '100%',
        overflow: 'auto',
        display: 'grid',
        gridTemplateColumns: `50px repeat(${projects.length ? projects.length : 1}, 300px)`,
        gridTemplateRows: ' 80px repeat(7, minmax(min-content, auto))',

    }

    const sticky = {
        position: 'sticky',
        top: 0,
        zIndex: 2,
    }

    const stickyLeft = {
        position: 'sticky',
        left: 0,
        zIndex: 1,
    }

    const textStyle = {
        ...stickyLeft,
        // position: 'relative',
        width: '50px',
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: 'var(--main-border)',
        borderRight: 'var(--main-border)',
        backgroundColor: 'var(--alt-bg-color)',
    }

    useEffect(() => {
        getProjects();


        const TIME = 240000; //4 minute refresh
        const autoUpate = setInterval(() => {
            setRefresh(true);
            console.log("projects refreshed")
        }, TIME)



        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
            clearInterval(autoUpate);
        }

    }, [])

    const getProjects = () => {
        const url = "/wp-json/wp/v2/project/?per_page=100";
        axios.get(
            url,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            let data = res.data;
            let list = [];
            for (let proj of data) {
                list.push(
                    {
                        id: proj.id,
                        title: proj.title.rendered,
                        position: proj.acf.position,
                        project_ID: proj.acf.projects
                    }
                )
            }
            list.sort((a, b) => {
                if (a.position < b.position) return -1;
                if (a.position > b.position) return 1;
                if (a.position == b.position) return 0;
            })
            setProjects(list);
            // console.log("Project:::", list);
        }).catch(err => {
            console.log("Error::getProj", err);
        })
    }

    const toggleBar = () => {
        if (!open && !auth) {
            return;
        }
        setOpen(!open);
    }

    useEffect(() => {
        if (projects) {
            //  console.log("State of projects: ", projects);

            setCarousel();
        }
        projectsRef.current = projects;
    }, [projects])

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
            getProjects();
        }
    }, [refresh]);

    const decrementWeek = () => {
        var newWeek = week.subtract(7, "days");
        setWeek(newWeek);
        setRefresh(true);
    }

    const incrementWeek = () => {
        var newWeek = week.add(7, "days");
        setWeek(newWeek);
        setRefresh(true);
    }

    const setCarousel = () => {
        const a = [];
        a.push("caret");
        for (let p of projects) {
            a.push(p.id);
        }
        carouselRef.current = a;

    }

    const nextItem = () => {
        carouselIndex++;
        carouselIndex = carouselIndex % carouselRef.current.length;
        let id = "project-" + carouselRef.current[carouselIndex];

        let el = document.getElementById(id);

        //check if element if already visible
        if (isVisible(el)) {
            // console.log(id + " visible");
            nextItem();
        } else {
            // console.log(id + " not visible");
        }

        el.scrollIntoView({
            behavior: 'smooth'
        });

        return;
    }

    const startScroll = () => {
        timerRef.current = setInterval(() => {
            nextItem();
            console.log("--scroll--");
        }, 3000);
        console.log("scroll started");
        setScroll(true);
    }

    const stopScroll = () => {
        clearInterval(timerRef.current)
        console.log("scroll stop");
        setScroll(false)
    }

    const isVisible = (ele) => {
        var rect = ele.getBoundingClientRect();

        // checking whether fully visible
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const toggleMultiSelect = () => {
        setMultiSelect(!multiSelect);
    }

    return (
        <>

            <div style={{
                position: 'fixed',
                right: '10px',
                bottom: '18px',
                display: 'flex',
            }}>
                <FloatingButton onClick={decrementWeek} tooltip="previous week" >
                    <AiFillCaretUp style={{
                        fontSize: '30px'
                    }} />
                </FloatingButton>
                <FloatingButton onClick={incrementWeek} tooltip="next week">
                    <AiFillCaretDown
                        style={{
                            fontSize: '30px'
                        }}
                    />
                </FloatingButton>
                {!scroll ? <FloatingButton onClick={startScroll} tooltip="auto scroll" >
                    <CgPlayForwards
                        style={{
                            fontSize: '30px'
                        }}
                    />
                </FloatingButton>
                    :
                    <FloatingButton onClick={stopScroll} style={{ border: '1px solid var(--confirm-bg-color)', backgroundColor: 'var(--confirm-bg-color)' }}>
                        <CgPlayForwards
                            style={{
                                fontSize: '30px'
                            }}
                        />
                    </FloatingButton>}


                <FloatingButton
                    tooltip="multi select"
                    style={{
                        border: multiSelect ? '1px solid var(--confirm-bg-color)' : '1px solid var(--alt-bg-color)',
                        backgroundColor: multiSelect ? 'var(--confirm-bg-color)' : 'var(--alt-bg-color)'
                    }}
                    onClick={toggleMultiSelect}
                >
                    <BiSelectMultiple
                        style={{
                            fontSize: '30px'
                        }}
                    />
                </FloatingButton>
            </div>
            <div
                ref={mainContainerRef}
                style={container}
            >

                {/*icon*/}
                <div
                    id="project-caret"
                    style={{
                        ...sticky,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderBottom: 'var(--main-border)',
                        backgroundColor: 'var(--alt-bg-color)',

                    }}
                >
                    {open ? <AiFillCaretLeft
                        style={{
                            fontSize: '30px',
                            cursor: 'pointer',
                        }}
                        onClick={toggleBar}
                    />
                        :
                        <AiFillCaretRight
                            style={{
                                fontSize: '30px',
                                cursor: 'pointer',
                            }}
                            onClick={toggleBar}
                        />
                    }
                </div>

                {/* project header */}
                {week && projects && projects.map((item, index) => {
                    return (
                        <div id={"project-" + item.id} key={index} style={{ ...titleStyle, ...sticky }}>
                            <h4 style={{
                                color: 'black',
                                fontSize: '30px'
                            }}>{item.title}</h4>
                        </div>
                    )
                })}

                <div style={{ ...textStyle, top: '80px', zIndex: 3 }} >
                    <p style={rotate}>
                        <span>
                            Equipment
                        </span>
                    </p>
                </div>
                {week && projects && projects.map((project, index) => {
                    let date = "equipment"
                    let ids = project.project_ID.split('|');
                    ids = ids.filter(x => x != "");
                    ids = ids.filter(item => item.includes(date));

                    return (
                        <Day
                            key={"equipment" + index}
                            projectId={project.id}
                            pRef={projectsRef}
                            date={date}
                            cards={ids}
                            bg="#f9f9f9"
                            minHeight="200px"
                            sticky
                        />
                    )
                })}

                <MinimiseRow>
                    <div style={textStyle} >
                        <p style={rotate}>
                            <span>
                                Monday
                            </span>
                            <span >
                                {dateToString(week, 1)}
                            </span>
                        </p>
                    </div>
                    {week && projects && projects.map((project, index) => {
                        let date = dateToString(week, 1);
                        let ids = project.project_ID.split('|');
                        ids = ids.filter(x => x != "");
                        ids = ids.filter(item => item.includes(date));

                        return (
                            <Day
                                key={"monday" + index}
                                projectId={project.id}
                                pRef={projectsRef}
                                date={date}
                                cards={ids}
                                bg="#9faeff66" />
                        )
                    })}
                </MinimiseRow>

                <MinimiseRow>

                    <div style={textStyle} >
                        <p style={rotate}>
                            <span>
                                Tuesday
                            </span>
                            <span >{dateToString(week, 2)}</span>
                        </p>
                    </div>
                    {projects.map((project, index) => {
                        let date = dateToString(week, 2);
                        let ids = project.project_ID.split('|');
                        ids = ids.filter(x => x != "");
                        ids = ids.filter(item => item.includes(date));

                        return (
                            <Day
                                key={"tuesday" + index}
                                projectId={project.id}
                                pRef={projectsRef}
                                date={date}
                                cards={ids}
                                bg="#f9f9f9" />
                        )
                    })}
                </MinimiseRow>


                <MinimiseRow>

                    <div style={textStyle} >
                        <p style={rotate}>
                            <span>Wednesday</span>

                            <span >{dateToString(week, 3)}</span>
                        </p>
                    </div>
                    {projects.map((project, index) => {
                        let date = dateToString(week, 3);
                        let ids = project.project_ID.split('|');
                        ids = ids.filter(x => x != "");
                        ids = ids.filter(item => item.includes(date));

                        return (
                            <Day
                                key={"wednesday" + index}
                                projectId={project.id}
                                pRef={projectsRef}
                                date={date}
                                cards={ids}
                                bg="#9faeff66" />
                        )
                    })}
                </MinimiseRow>

                <MinimiseRow>

                    <div style={textStyle} >
                        <p style={rotate}>
                            <span>Thursday</span>

                            <span >{dateToString(week, 4)}</span>
                        </p>
                    </div>
                    {projects.map((project, index) => {
                        let date = dateToString(week, 4);
                        let ids = project.project_ID.split('|');
                        ids = ids.filter(x => x != "");
                        ids = ids.filter(item => item.includes(date));

                        return (
                            <Day
                                key={"thursday" + index}
                                projectId={project.id}
                                pRef={projectsRef}
                                date={date}
                                cards={ids}
                                bg="#f9f9f9" />
                        )
                    })}
                </MinimiseRow>


                <MinimiseRow>

                    <div style={textStyle} >
                        <p style={rotate}>
                            <span>Friday</span>

                            <span >{dateToString(week, 5)}</span>
                        </p>
                    </div>
                    {projects.map((project, index) => {
                        let date = dateToString(week, 5);
                        let ids = project.project_ID.split('|');
                        ids = ids.filter(x => x != "");
                        ids = ids.filter(item => item.includes(date));

                        return (
                            <Day
                                key={"friday" + index}
                                projectId={project.id}
                                pRef={projectsRef}
                                date={date}
                                cards={ids}
                                bg="#9faeff66"
                            />
                        )
                    })}
                </MinimiseRow>

                <MinimiseRow>

                    <div style={textStyle} >
                        <p style={rotate}>
                            <span>Saturday</span>

                            <span >{dateToString(week, 6)}</span>
                        </p>
                    </div>
                    {projects.map((project, index) => {
                        let date = dateToString(week, 6);
                        let ids = project.project_ID.split('|');
                        ids = ids.filter(x => x != "");
                        ids = ids.filter(item => item.includes(date));

                        return (
                            <Day
                                key={"saturday" + index}
                                projectId={project.id}
                                pRef={projectsRef}
                                date={date}
                                cards={ids}
                                bg="#f9f9f9"
                            />
                        )
                    })}
                </MinimiseRow>




            </div>
        </>
    );
}

const rotate = {
    display: 'flex',
    flexDirection: 'column',
    transform: 'rotate(-90deg)'
}

const titleStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'var(--alt-bg-color)',
    padding: '15px',
    width: '100%',
    borderBottom: 'var(--main-border)',
    borderLeft: 'var(--main-border)',
    boxSizing: 'border-box',

}

export default ProjectPanel;