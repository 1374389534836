import { atom } from "recoil";
import moment from "moment";

export const Auth = atom({
    key: 'auth',
    default: false
})

export const refreshProject = atom({
    key: 'refreshProject',
    default: false
})

export const Names = atom({
    key: 'names',
    default: []
})

export const Projects = atom({
    key: 'projects',
    default: []
})

export const OpenToolbar = atom({
    key: 'openToolbar',
    default: true
})

export const CurrentWeek = atom({
    key: 'currentWeek',
    default: moment().startOf('week'),
})

export const ProjectChanges = atom({
    key: 'projectChanges',
    default: []
})

export const MultiSelect = atom({
    key: 'multiSelect',
    default: false
})