import React, { useState, useEffect } from 'react';
import Card from '../visual/Card';

import Radium from 'radium';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../util/types';
import { RiCloseFill } from 'react-icons/ri';


const Item = ({ data, id, remove, removable, hide }) => {

    const [itemId, setItemId] = useState('');

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => ({ id, data }),
        end: (item, monitor) => doResult(item, monitor),
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });

    const doResult = (item, monitor) => {
        let result = monitor.getDropResult() ? monitor.getDropResult().result : null;
        if (!result) return;
        if (result == "item add successful" && remove) {
            remove(item.id);
            console.log("trying to remove.." + item.id);
        }
    }


    useEffect(() => {
        setItemId(id);
    }, [])

    const itemStyle = {
        display: 'flex',
        backgroundColor: data && data.bg ? data.bg : '#ffffff',
        margin: '1%',
        cursor: 'pointer',
        ':hover': {
            opacity: 0.8,
        },
        justifyContent: 'center'
    }

    const cardStyle = {
        boxShadow: 'var(--box-shadow)',
        width: 'auto',
        height: 'auto',
        padding: '2%',
        position: 'relative'

    }

    const labelStyle = {
        color: data && data.color ? data.color : 'var(--main-text-color)',
        fontWeight: 'bold',
        fontFamily: data && data.font ? data.font : 'var(--secondary-font)'
    }

    const parseTitle = (title) => {
        return title.replace("&#8217;", "'");
    }

    const isDark = (hex) => {
        var c = hex.substring(1);      // strip #
        var rgb = parseInt(c, 16);   // convert rrggbb to decimal
        var r = (rgb >> 16) & 0xff;  // extract red
        var g = (rgb >> 8) & 0xff;  // extract green
        var b = (rgb >> 0) & 0xff;  // extract blue

        var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        if (luma < 40) {
            return true;
        }

        return false;
    }


    return (
        data ?
            <div
                ref={drag}
                id={itemId}
                style={[itemStyle, cardStyle]}
            >
                <p style={labelStyle}>{parseTitle(data.title)}</p>
                {removable && <RiCloseFill style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '6px',
                    color: isDark(itemStyle.backgroundColor) ? '#ffffff' : '#000000',
                    ':hover': {
                        opacity: 0.8,
                    },
                }}
                    onClick={() => remove(itemId)}
                />}
            </div> : ''
    );
}


export default Radium(Item);