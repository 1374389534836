import React from 'react';
import Radium from 'radium';

const Button = (props) => {

    const style = {
        display: 'flex',
        backgroundColor: props.bg ? props.bg : 'var(--main-color)',
        color: 'var(--alt-text-color)',
        width: props.width ? props.width : '50%',
        height: props.height ? props.height : 'auto',
        padding: '5px',
        borderRadius: '5px',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'var(--box-shadow)',
        cursor: 'pointer',
        fontWeight: '500',
        ':hover': {
            opacity: 0.8
        },
        ':active': {
            boxShadow: 'none'
        }
    }

    if (props.disabled) {
        style.backgroundColor = '#6b6b6b'
    }

    return (
        <div style={{ ...style, ...props.btnstyle }} {...props}>
            {props.children}
        </div>
    );
}

export default Radium(Button);