import React, { useState } from 'react';

import { CirclePicker } from 'react-color';

const Color = (props) => {

    const [selectedColor, setSelectedColor] = useState(props.color ? props.color : '#000000');
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    }

    const changeColor = (color) => {
        setSelectedColor(color.hex);
        props.onChangeColor(color.hex);
    }


    const lblStyle = {
        fontSize: '15px',
        fontWeight: '450',
        marginBottom: '3px',
    }

    const container = {
        display: 'flex',
        flexDirection: 'column'
    }

    const visual = {
        width: '100%',
        height: '25px',
        backgroundColor: selectedColor ? selectedColor : '#ff0000',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const pickerContainer = {
        padding: '5px'
    }

    const colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7",
        "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4",
        "#009688", "#4caf50", "#8bc34a", "#cddc39",
        "#ffeb3b", "#ffc107", "#ff9800", "#ff5722",
        "#795548", "#607d8b", "#dddddd", "#000000"]


    return (
        <div style={{ ...container, ...props.containerStyle }}>
            <label style={lblStyle}>{props.label}</label>
            <div onClick={toggleOpen} style={visual}>
                <p>{open ? 'click to close' : 'click to  choose'}</p>
            </div>

            {open && <div style={pickerContainer}>
                <CirclePicker
                    color={selectedColor}
                    onChangeComplete={changeColor}
                    circleSpacing={5}
                    circleSize={20}
                    colors={colors}
                />
            </div>}

        </div>
    );
}



export default Color;