import React, { useState } from 'react';

import Container from '../visual/Container';
import Textbox from '../visual/Textbox';
import Select from '../visual/Select';
import Button from '../visual/Button';

const AddProject = ({ onSave, error }) => {

    const [name, setName] = useState('');
    const [position, setPosition] = useState(0);

    const update = (event) => {
        if (event.target.name === 'name') {
            setName(event.target.value);
        }

        if (event.target.name === 'position') {
            setPosition(event.target.value);
        }
    }

    const save = () => {
        let data = {
            name: name,
            position: position,
        }

        onSave(data);
    }

    const list = [0, 1, 2, 3, 4, 5, 6, 7];

    return (
        <Container style={container}>

            <Textbox
                type="text"
                label="Project Name"
                name="name"
                onChange={update}
                value={name}
                txtstyle={{ padding: '5px' }}
                autoComplete="off"
            />

            <Select
                id="position"
                label="Position"
                name="position"
                list={list}
                containerStyle={{ padding: '5px' }}
                value={position}
                onChange={update}
            />

            <Button
                onClick={save}
                width="auto"
                btnstyle={
                    {
                        margin: '5px'
                    }
                }
            >
                Save
            </Button>

        </Container>
    );
}

const container = {
    flexDirection: 'column',
    marginTop: '10px',
    backgroundColor: 'var(--card-surface)'
}

const lblStyle = {
    fontSize: '15px',
    fontWeight: '450',
    marginBottom: '3px',
}

export default AddProject;