import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { RecoilRoot } from 'recoil';

import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import Navbar from './components/Navbar';
import ProjectPanel from './screens/ProjectPanel';


function App() {
  return (
    <RecoilRoot>
      <Router>
        <DndProvider backend={HTML5Backend}>
          <div className="App">
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Dashboard />} >
                <Route exact path="/projects" element={<ProjectPanel />} />

                <Route path="/login" exact element={<Login />} />
              </Route>

            </Routes>
          </div>
        </DndProvider>
      </Router>
    </RecoilRoot>
  );
}

export default App;
