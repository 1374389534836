import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { Auth } from '../State';

import Container from '../visual/Container';
import Card from '../visual/Card';
import Textbox from '../visual/Textbox';
import Button from '../visual/Button';


const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const [auth, setAuth] = useRecoilState(Auth);

    let navigate = useNavigate();

    const submit = (event) => {
        event.preventDefault();
        const url = "/wp-json/jwt-auth/v1/token";

        axios({
            method: 'post',
            url: url,
            data: {
                username: username,
                password: password
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            console.log("res", response.data)
            localStorage.setItem('whiteboard_token', response.data.token);
            setAuth(true);
            navigate('/projects');
        }).catch(error => {
            const err = error;
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setError(err.response.data.message);
            }
        });
    }

    const update = (event) => {
        if (event.target.name === "username") {
            setUsername(event.target.value);
        }

        if (event.target.name === "password") {
            setPassword(event.target.value);
        }

        setError('');
    }

    return (
        <Container style={containerStyle}>
            <Card
                width="300px"
            >
                <h2 style={{ fontWeight: 400, padding: '5px' }}>Sign In</h2>

                <form action="" onSubmit={submit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Textbox
                        type="text"
                        label="Username"
                        name="username"
                        onChange={update}
                        value={username}
                        txtstyle={{ padding: '5px' }}
                    />
                    <Textbox
                        type="password"
                        label="Password"
                        name="password"
                        onChange={update}
                        value={password}
                        txtstyle={{ padding: '5px' }}
                    />

                    <Button
                        onClick={submit}
                        width="auto"
                        btnstyle={{
                            margin: '10px 5px 10px 5px'
                        }}
                    >
                        Sign In
                    </Button>

                    {/* when users press enter */}
                    <input style={{ display: 'none' }} type="submit" />

                    <p
                        style={{
                            fontSize: '12px',
                            alignSelf: 'center',
                            textDecoration: 'underline',
                            color: '#999',
                            marginTop: '10px',
                            marginBottom: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={() => alert('Please contact your administrator!')}
                    >Forgot Password</p>
                    {error !== '' ? <p style={{
                        fontSize: '14px',
                        color: 'var(--error-color)',
                        padding: '5px',
                        cursor: 'pointer'
                    }} dangerouslySetInnerHTML={{ __html: error }}></p> : ''}
                </form>

            </Card>
        </Container>
    );
}

const containerStyle = {
    justifyContent: 'center',
    alignItems: 'center',

}

export default Login;