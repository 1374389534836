import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';


import Container from '../visual/Container';
import Button from '../visual/Button';
import ItemPanel from '../components/ItemPanel';
import Radium from 'radium';
import { Outlet } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { MdMenu } from 'react-icons/md';
import AddItems from '../components/AddItems';
import { saveProjectUtil } from '../util/Functions';
import { Auth, refreshProject, Names, OpenToolbar } from '../State';



const Dashboard = () => {

    const [staff, setStaff] = useState(true);
    const [equip, setEquip] = useState(false);
    const [notes, setNotes] = useState(false);

    //global state
    const [names, setNames] = useRecoilState(Names);
    const [auth, setAuth] = useRecoilState(Auth);
    const [open, setOpen] = useRecoilState(OpenToolbar);

    const [showAdd, setShowAdd] = useState(false);
    const [addErr, setAddError] = useState('');


    const [refresh, setRefresh] = useRecoilState(refreshProject);

    const togglePanel = (event) => {
        if (event.target.id === 'staff') {
            setStaff(true);
            setEquip(false);
            setNotes(false);
        } else if (event.target.id === 'equip') {
            setStaff(false);
            setEquip(true);
            setNotes(false);
        } else {
            setStaff(false);
            setEquip(false);
            setNotes(true);
        }
    }

    const toggleAdd = (event) => {
        if (event && event.type === "click") {
            setShowAdd(!showAdd);
        } else {
            console.log("event", event);
        }

    }

    const getStaff = () => {
        const url = "/wp-json/wp/v2/name/?per_page=100";
        let number_of_pages;
        let current_page = 1
        axios.get(
            url,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            let data = res.data;
            let l = [];
            for (let name of data) {
                l.push(
                    {
                        id: name.id,
                        color: name.acf.color,
                        font: name.acf.font,
                        bg: name.acf.background,
                        title: name.title.rendered,
                        type: name.acf.type
                    }
                )
            }
            setNames(l);
            number_of_pages = res.headers['x-wp-totalpages'];

            if (current_page < number_of_pages) {
                getNextPageStaff(current_page + 1);
            }

            console.log("getStaff:::", res);
        }).catch(err => {
            console.log("Error::GetStaff", err);
        })



    }

    const getNextPageStaff = (page) => {
        const url = "/wp-json/wp/v2/name/?per_page=100&page=" + page;
        let number_of_pages;
        axios.get(
            url,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            let data = res.data;
            let l = [];
            for (let name of data) {
                l.push(
                    {
                        id: name.id,
                        color: name.acf.color,
                        font: name.acf.font,
                        bg: name.acf.background,
                        title: name.title.rendered,
                        type: name.acf.type
                    }
                )
            }

            setNames(prev => [...prev, ...l]);
            number_of_pages = res.headers['x-wp-totalpages'];


            if (page < number_of_pages) {
                getNextPageStaff(page + 1);
            }

            console.log("ExtraPageAdded:::", res);


        }).catch(err => {
            console.log("Error::ExtraPage", err);
        })
    }



    const saveStaff = (data) => {
        let token = localStorage.getItem('whiteboard_token');
        let body = {
            title: data.name,
            status: 'publish',
            acf: {
                color: data.color,
                background: data.bg,
                font: data.font,
                type: "staff"
            }
        }

        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        let url = "/wp-json/wp/v2/name";

        axios.post(url, body, header).then(response => {
            console.log("created staff id: ", response.data.id);
            setShowAdd(false);
            getStaff();
        }).catch(error => {
            const err = error;
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setAddError(err.response.data.message);
            }
        })
    }

    const saveEquip = (data) => {
        let token = localStorage.getItem('whiteboard_token');
        let body = {
            title: data.name,
            status: 'publish',
            acf: {
                color: data.color,
                background: data.bg,
                font: data.font,
                type: "equipment"
            }
        }

        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        let url = "/wp-json/wp/v2/name";

        axios.post(url, body, header).then(response => {
            console.log("created equipment id: ", response.data.id);
            setShowAdd(false);
            getStaff();
        }).catch(error => {
            const err = error;
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setAddError(err.response.data.message);
            }
        })
    }

    const saveProject = (data) => {
        saveProjectUtil(data, response => {
            console.log("created project id: ", response.data.id);
            setShowAdd(false);
            setRefresh(true);
        }, error => {
            const err = error;
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setAddError(err.response.data.message);
            }
        });

    }

    const saveNote = (data) => {
        let token = localStorage.getItem('whiteboard_token');
        let body = {
            title: data.name,
            status: 'publish',
            acf: {
                color: data.color,
                background: data.bg,
                font: data.font,
                type: "notes"
            }
        }

        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        let url = "/wp-json/wp/v2/name";

        axios.post(url, body, header).then(response => {
            console.log("created note id: ", response.data.id);
            setShowAdd(false);
            getStaff();
        }).catch(error => {
            const err = error;
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setAddError(err.response.data.message);
            }
        })
    }

    useEffect(() => {
        getStaff();
    }, []);



    return (
        <Container>
            <AddItems
                onSaveStaff={saveStaff}
                onSaveEquip={saveEquip}
                onSaveProject={saveProject}
                onSaveNote={saveNote}
                close={toggleAdd}
                visible={showAdd}
                opacity={0.4}
                error={addErr} />
            <Container style={{
                backgroundColor: '#f9f9f9',
                maxWidth: auth && open ? '400px' : 'min-content',
                borderRight: 'var(--main-border)',
                flexDirection: 'column',
            }}>
                {
                    auth && open ?
                        <>
                            <div style={headStyle}>
                                <h4 style={{
                                    fontWeight: 450,
                                    fontSize: '15px',
                                    letterSpacing: '0.05rem',
                                    padding: '5px'
                                }}>
                                    Tools
                                </h4>
                                <p
                                    key="staff"
                                    id='staff'
                                    onClick={togglePanel}
                                    style={{ ...selectStyle, borderBottom: staff ? selected.borderBottom : '' }} >
                                    Staff
                                </p>
                                <p
                                    key="equip"
                                    id='equip'
                                    onClick={togglePanel}
                                    style={{ ...selectStyle, borderBottom: equip ? selected.borderBottom : '' }}>
                                    Equipment
                                </p>
                                <p
                                    key="notes"
                                    id='notes'
                                    onClick={togglePanel}
                                    style={{ ...selectStyle, borderBottom: notes ? selected.borderBottom : '' }}>
                                    Notes
                                </p>
                                <Button
                                    width="50px"
                                    btnstyle={{
                                        marginLeft: 'auto'
                                    }}
                                    onClick={toggleAdd}
                                >
                                    Add
                                </Button>
                            </div>

                            <ItemPanel
                                items={names.filter(item => item.type == "staff")}
                                visible={staff}
                            />

                            <ItemPanel
                                items={names.filter(item => item.type == "equipment")}
                                visible={equip}
                            />

                            <ItemPanel
                                items={names.filter(item => item.type == "notes")}
                                visible={notes}
                            />
                        </>

                        :
                        <div
                            style={{
                                padding: '2px',
                                width: 'min-content'
                            }}
                        >
                            <MdMenu style={{
                                fontSize: '30px',
                                cursor: 'pointer',
                            }} />
                        </div>
                }
            </Container >
            <Outlet />
        </Container>

    );
}

const headStyle = {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 'var(--main-border)',
    width: 'auto',
    height: 'fit-content',
    padding: '10px',
}

const selectStyle = {
    fontWeight: 300,
    fontSize: '15px',
    padding: '5px',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: "#ebe9e9"
    },
}

const selected = {
    borderBottom: '2px solid #7c7c7c'
}



export default Radium(Dashboard);