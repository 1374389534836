import React from 'react';

import Card from '../visual/Card';

const Modal = (props) => {

    const opacity = props.opacity ? props.opacity : '0.1';

    const container = {
        backgroundColor: '#000000',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        height: '100%',
        width: '100%',
        zIndex: 1000,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,' + opacity + ')',
    }

    const close = (event) => {
        props.close(event);
    }

    const cardClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }

    if (props.visible) {
        return (
            <div id="modal_container" style={container} onClick={close}>
                <Card
                    style={props.cardStyle}
                    onClick={cardClick}
                >
                    {props.children}
                </Card>
            </div>
        );
    } else {
        return (
            <div style={{ display: 'none' }}></div>
        );
    }


}



export default Modal;