import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const FloatingButton = (props) => {

    const container = {
        backgroundColor: 'var(--card-surface)',
        width: '50px',
        height: '50px',
        border: '1px solid var(--card-surface)',
        borderRadius: '50%',
        margin: '5px',
        boxShadow: 'var(--box-shadow)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }

    return (
        <>
            <Tippy content={props.tooltip ? props.tooltip : ''}>
                <div style={{ ...container, ...props.style }} onClick={props.onClick}>
                    {props.children}
                </div>
            </Tippy>
        </>
    );
}

export default FloatingButton;