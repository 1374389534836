import React, { useEffect } from 'react';
import { Projects } from '../State';
import { useRecoilValue } from 'recoil';

import Container from '../visual/Container';
import Item from './Item';

const ItemPanel = ({ items, visible }) => {

    const projects = useRecoilValue(Projects);

    useEffect(() => {

    }, [visible])

    const isOnBoard = (id) => {
        let contained = false;
        for (let p of projects) {
            if (p.project_ID.includes("|" + id + ":")) {
                contained = true;
                break;
            }
        }

        return contained;
    }





    return (
        <Container style={{
            display: visible ? 'flex' : 'none',
            flexDirection: 'column',
            backgroundColor: '#efefef',
            padding: '2%',
            height: 'auto',
            maxHeight: 'calc(100% - 51px)',
            maxHeight: '-moz-calc(100% - 51px)',
            maxHeight: '-o-calc(100% - 51px)',
            maxHeight: '-webkit-calc(100% - 51px)',
            overflow: 'auto',
        }}

        >

            {
                items.map((item, index) => {

                    if (isOnBoard(item.id)) {
                        //  console.log("item: " + item.id + " already on board");
                    } else {

                        return (
                            <Item
                                key={index}
                                data={item}
                                id={"panel-" + item.id} />
                        )

                    }

                })
            }

        </Container>
    );
}

export default ItemPanel;