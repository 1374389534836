import React, { useEffect, useState } from 'react';

import { MdAccountCircle } from 'react-icons/md';
import Radium from 'radium';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import { Auth, ProjectChanges, Projects } from '../State';
import { updateProjectUtil } from '../util/Functions';

import Button from '../visual/Button';
import { Oval } from 'react-loader-spinner'

const Navbar = () => {

    const navStyle = {
        display: 'flex',
        backgroundColor: 'var(--main-nav-color)',
        padding: '10px',
        borderBottom: '1px solid #e6e6e6',
    }

    const linkStyle = {
        color: 'var(--main-text-color)',
        fontWeight: '500',
        fontFamily: 'var(--main-font)',
        textDecoration: 'none',
        padding: '5px',
        ':hover': {
            color: '#000000'
        }
    }

    const atag = {
        color: 'var(--main-text-color)',
        fontWeight: '500',
        fontFamily: 'var(--main-font)',
        textDecoration: 'none',
        padding: '5px',
        ':hover': {
            color: '#000000'
        }
    }

    const iconStyle = {
        fontSize: '30px',
        marginLeft: 'auto',
        color: 'var(--main-color)',
        cursor: 'pointer',

    }

    const [showSavebtn, setShowSavebtn] = useState(false);
    const [saving, setSaving] = useState(false);

    const [auth, setAuth] = useRecoilState(Auth);
    const [changes, setChanges] = useRecoilState(ProjectChanges);
    const projects = useRecoilValue(Projects);

    const logout = () => {
        localStorage.removeItem('whiteboard_token');
        setAuth(false);
    }


    useEffect(() => {
        const url = "/wp-json/jwt-auth/v1/token/validate";
        const token = localStorage.getItem('whiteboard_token');
        axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(response => {
            console.log("res", response.data);
            setAuth(true);
        }).catch(error => {
            const err = error;
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
            setAuth(false);
        });
    }, []);


    const applyUpdates = async () => {
        if (auth) {
            setSaving(true)
            const responses = [];

            for (let project of projects) {
                let res = await updateProjectUtil(project, null, null);
                responses.push(res);
            }

            console.log("response array:", responses);

            //clear changes
            setChanges([]);
            setSaving(false);

        }
    }

    useEffect(() => {
        if (changes.length > 0) {
            setShowSavebtn(true);
        } else {
            setShowSavebtn(false);
        }

        //  console.log("changes to save", changes);
    }, [changes])

    return (
        <div style={navStyle}>
            <a key="1" style={linkStyle} href="/projects">Whiteboard</a>
            {showSavebtn && !saving ? <Button
                width="auto"
                bg="var(--confirm-bg-color)"
                disabled={auth ? false : true}
                btnstyle={{ marginLeft: '15px' }}
                onClick={applyUpdates}
            >
                Save Changes
            </Button> : null}

            {saving && <div

                style={{
                    height: '30px',
                    width: '30px',
                    position: 'relative',
                    marginLeft: '15px'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '-25px',
                        left: '-10px',
                    }}
                >
                    <Oval
                        heigth="35"
                        width="35"
                        color='#06cb05'
                        ariaLabel='loading'
                    />
                </div>

            </div>}



            {!auth && <a key="2" style={{ ...atag, marginLeft: 'auto' }} href="/login">Login</a>}

            {auth && <MdAccountCircle onClick={logout} style={iconStyle} />}

        </div>
    );
}

export default Radium(Navbar);
