import React, { useEffect, useRef, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Names, Projects, ProjectChanges, refreshProject, MultiSelect } from '../State';
import PanelCard from '../components/PanelCard';

import Radium from 'radium';
import { useDrop, useDrag } from 'react-dnd';
import { ItemTypes } from '../util/types';
import { replace } from '../util/Functions';


const Day = (props) => {


    const [items, setItems] = useState([]);
    const [uid] = useState(_uniqueId('day-'))

    const [projects, setProjects] = useRecoilState(Projects);
    const names = useRecoilValue(Names);
    const [changes, setChanges] = useRecoilState(ProjectChanges);
    const multiSelect = useRecoilValue(MultiSelect);

    const itemsRef = useRef([]);
    const dayContainerRef = useRef(null);
    // const itemRef = useRef([]);
    //const projectsRef = useRef([]);


    const [{ isOver }, drop] = useDrop({
        accept: [ItemTypes.CARD, ItemTypes.MULTI],
        drop: (item, monitor) => performActions(item, monitor),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        })
    })

    //For use in multiselect
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.MULTI,
        item: () => ({ id: uid, data: items }),
        end: (item, monitor) => doResult(item, monitor),
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });

    const doResult = (item, monitor) => {
        let result = monitor.getDropResult() ? monitor.getDropResult().result : null;
        if (!result) return;
        if (result == "remove all") {
            removeAllItems();
        }
    }

    const performActions = (item, monitor) => {
        let result = null;
        if (monitor.getItemType() == ItemTypes.CARD) {
            result = addItem(item.data);
            return result;
        }

        if (monitor.getItemType() == ItemTypes.MULTI) {

            if (uid == item.id) {
                result = {
                    result: 'invalid'
                }
            } else {
                addMultipleItems(item.data);

                result = {
                    result: 'remove all'
                }
            }

        }

        return result;

    }


    const addMultipleItems = (data) => {
        let items_to_add = [];
        let result = "invalid"
        for (let i of data) {

            var found = false;
            for (let item of items) {
                if (item.id == i.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                items_to_add.push(i);
            }


        }

        if (items_to_add.length > 0) {
            let array = items.concat(items_to_add);
            itemsRef.current = [...array];
            setItems(array);
            result = "item add successful";

            let obj = {
                type: 'multi_add',
                target: props.projectId,
                card_ids: items_to_add,
                date: props.date
            }

            //add to project changes
            setChanges(prev => [...prev, obj]);

            let oldData = projects.find(p => p.id == props.projectId);
            let oldArray = props.pRef.current
            let newArray = [];
            for (let i of items_to_add) {

                //add to projects array
                let info = "|" + i.id + ":" + props.date;

                newArray = replace(oldArray, oldData, props.projectId, { project_ID: info });

                oldArray = newArray;
                oldData = newArray.find(p => p.id == props.projectId);
            }

            props.pRef.current = newArray;
            setProjects(newArray);

        }

        return {
            result: result,
        }
    }

    const removeAllItems = () => {
        let obj = {
            type: 'remove_all',
            target: props.projectId,
            card_ids: items,
            date: props.date
        }

        //add to project changes
        setChanges(prev => [...prev, obj]);


        let oldData = projects.find(p => p.id == props.projectId);
        let oldArray = props.pRef.current
        let newArray = [];
        for (let i of items) {

            let info = "|" + i.id + ":" + props.date;
            let data_insert = {
                project_ID: oldData.project_ID.replace(info, "")
            };
            newArray = replace(oldArray, oldData, props.projectId, data_insert, "replace");

            oldArray = newArray;
            oldData = newArray.find(p => p.id == props.projectId);
        }

        props.pRef.current = newArray;
        setProjects(newArray);

        itemsRef.current = [];
        setItems(itemsRef.current);
        console.log("All items removed");
    }


    const addItem = (data) => {
        var found = false;
        var result = "item already exsists"
        for (let item of items) {
            if (item.id == data.id) found = true;
        }

        if (!found) {
            let array = JSON.parse(JSON.stringify(items));
            array.push(data);
            itemsRef.current = [...array];
            setItems(array);
            result = "item add successful";

            let obj = {
                type: 'add',
                target: props.projectId,
                card_id: data.id,
                date: props.date
            }

            //add to project changes
            setChanges(prev => [...prev, obj]);

            //add to projects array
            let info = "|" + data.id + ":" + props.date;


            let oldData = projects.find(p => p.id == props.projectId);
            let newArray = replace(props.pRef.current, oldData, props.projectId, { project_ID: info });
            props.pRef.current = newArray;
            setProjects(newArray);

            // console.log("remaining items", itemsRef.current);
            // setProjects(oldArray => replace(oldArray, oldData, props.projectId, { project_ID: info }))

        }

        return {
            result: result,
        }
    }

    const removeItem = (id) => {

        let obj = {
            type: 'remove',
            target: props.projectId,
            card_id: id,
            date: props.date
        }

        //add to project changes
        setChanges(prev => [...prev, obj]);

        //new method
        let info = "|" + id + ":" + props.date;
        let oldData = projects.find(p => p.id == props.projectId);
        let data_insert = {
            project_ID: oldData.project_ID.replace(info, "")
        };
        let newArray = replace(props.pRef.current, oldData, props.projectId, data_insert, "replace");
        props.pRef.current = newArray;
        setProjects(newArray);


        //also remove from items array
        let array = JSON.parse(JSON.stringify(itemsRef.current));
        // console.log("items before filteration", array);
        const array2 = array.filter(x => x.id != id);
        itemsRef.current = array2;
        setItems(array2);
        console.log("item removed - resulting array", array2);

    }


    const updateItems = () => {
        let list = [];

        for (let card of props.cards) {
            let [id, date] = card.split(':');


            let found = names.find(n => n.id == id && props.date == date);
            list.push(found);
        }

        itemsRef.current = list;
        setItems(list);
    }

    useEffect(() => {
        if (props.cards && names) {
            updateItems();
        }
    }, [props.cards, names])

    useEffect(() => {
        if (props.cards && props.cards.length > 0) {
            updateItems();
        }

    }, [])

    useEffect(() => {
        if (items.length > 0) {
            let id = dayContainerRef.current.id;
            let offSetHeigth = document.getElementById('day-2').offsetHeight;

            // console.log("items: ", items.length);

            let stickyMax = Math.floor(200 / 50);
            let normalMax = Math.floor((window.innerHeight - 200 - offSetHeigth) / 50);

            let maxItems = props.sticky ? stickyMax : normalMax;
            let element = document.getElementById(id);
            if (items.length > maxItems) {
                element.style.display = 'grid';
            } else {
                element.style.display = 'flex';
            }
        }
    }, [items])

    const mulitRef = (element) => {
        drop(element);
        if (multiSelect) {
            drag(element);
        }
        dayContainerRef.current = element;
    }


    const changeBorderColor = (col) => {
        let str = col.split('');
        str[str.length - 1] = 'f';
        str[str.length - 2] = 'f';
        str = str.join('');

        if (col == "#f9f9f9") return "#dbdbdb"

        return str;
    }

    var container = {
        display: 'flex',
        gridTemplateColumns: '1fr 1fr',
        gridAutoRows: 'min-content',
        flexDirection: 'column',
        height: 'auto',
        minHeight: props.minHeight ? props.minHeight : '300px',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: props && props.bg ? props.bg : '',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: props && props.bg ? changeBorderColor(props.bg) : '',
    }

    if (props.sticky) {
        container = {
            ...container,
            position: 'sticky',
            top: '80px',
            zIndex: 2,
        }
    }

    return (
        <div
            id={uid}
            ref={mulitRef}
            style={{ ...container, ...props.style }}

        >
            {items && items.map((item, index) => {
                if (item && item.id) {
                    return (
                        <PanelCard id={item.id} key={index} data={item} date={props.date} remove={removeItem} removable />
                    )
                }

            })}

        </div>
    );
}



export default Radium(Day);