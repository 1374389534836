import React from 'react';
import Radium from 'radium';

const TextArea = (props) => {

    const div = {
        display: 'flex',
        flexDirection: 'column',

    }

    const lblStyle = {
        fontSize: '15px',
        fontWeight: '450',
        marginBottom: '3px',
    }

    const txtStyle = {
        width: props.width ? props.width : 'auto',
        height: props.height ? props.height : 'auto',
        fontFamily: 'var(--secondary-font)',
        outline: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#ccc',
        borderRadius: '4px',
        backgroundColor: '#fafafa',
        padding: '5px',
        ':hover': {
            borderColor: '#aaa'
        },
        ':focus': {
            borderColor: 'var(--main-color)'
        },


    }

    const errorStyle = {
        fontSize: '12px',
        padding: '3px',
        color: 'var(--error-color)'
    }

    return (
        <div style={{ ...div, ...props.txtstyle }}>
            <label style={lblStyle} htmlFor={props.name}>{props.label}</label>
            <textarea style={txtStyle} {...props} >
            </textarea>
            {props.error && <p style={errorStyle} >{props.error}</p>}
        </div>
    );
}

export default Radium(TextArea);