import React, { useState } from 'react';
import Radium from 'radium';

import Modal from '../visual/Modal';
import AddStaff from './AddStaff';
import AddEquip from './AddEquip';
import AddNote from './AddNote';
import AddProject from './AddProject';

const AddItems = (props) => {

    const [staff, setStaff] = useState(true);
    const [equip, setEquip] = useState(false);
    const [notes, setNotes] = useState(false);
    const [project, setProject] = useState(false);

    const togglePanel = (event) => {
        if (event.target.id === 'mod_staff') {
            setStaff(true);
            setEquip(false);
            setNotes(false);
            setProject(false);
        } else if (event.target.id === 'mod_equip') {
            setStaff(false);
            setEquip(true);
            setNotes(false);
            setProject(false);
        } else if (event.target.id === 'mod_notes') {
            setStaff(false);
            setEquip(false);
            setNotes(true);
            setProject(false);
        } else {
            setStaff(false);
            setEquip(false);
            setNotes(false);
            setProject(true);
        }
    }


    return (
        <Modal
            visible={props.visible}
            opacity={props.opacity}
            close={props.close}

        >
            <h4 >Add Items</h4>
            <div style={head}>
                <p
                    key={1}
                    style={{ ...text, borderBottom: staff ? selected.borderBottom : '' }}
                    id='mod_staff'
                    onClick={togglePanel}
                >
                    Staff
                </p>
                <p
                    key={2}
                    style={{ ...text, borderBottom: equip ? selected.borderBottom : '' }}
                    id='mod_equip'
                    onClick={togglePanel}
                >Equipment
                </p>
                <p
                    key={3}
                    style={{ ...text, borderBottom: notes ? selected.borderBottom : '' }}
                    id='mod_notes'
                    onClick={togglePanel}
                >Note
                </p>
                <p
                    key={4}
                    style={{ ...text, borderBottom: project ? selected.borderBottom : '' }}
                    id='mod_project'
                    onClick={togglePanel}
                >
                    Project
                </p>
            </div>
            {
                staff && <AddStaff
                    onSave={props.onSaveStaff}
                    error={props.error} />
            }
            {
                equip && <AddEquip
                    onSave={props.onSaveEquip}
                    error={props.error} />
            }

            {
                notes && <AddNote
                    onSave={props.onSaveNote}
                    error={props.error} />
            }
            {
                project && <AddProject
                    onSave={props.onSaveProject}
                    error={props.error}
                />
            }
        </Modal>
    );
}

const head = {
    display: 'flex',
    flexDirection: 'row',
}

const text = {
    fontSize: '14px',
    fontFamily: 'var(--secondary-font)',
    fontWeight: '500',
    padding: '5px',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: "#ebe9e9"
    },
}

const selected = {
    borderBottom: '2px solid #7c7c7c'
}

export default Radium(AddItems);

